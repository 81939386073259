export const COOKIE_CATEGORY = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
  SOCIAL_MEDIA: 'C0005',
  STACK_42: 'V2STACK42'
}

export const MAIN_COOKIE_CATEGORY_VALUES = [
  COOKIE_CATEGORY.STRICTLY_NECESSARY,
  COOKIE_CATEGORY.PERFORMANCE,
  COOKIE_CATEGORY.FUNCTIONAL,
  COOKIE_CATEGORY.TARGETING,
  COOKIE_CATEGORY.SOCIAL_MEDIA
]

export const COOKIE_ENTITY_TYPE = {
  DFP_BANNER: 'DFP_BANNER',
  VIDEO_BANNER: 'VIDEO_BANNER',
  DISQUS: 'DISQUS',
  RE_CAPTCHA: 'RE_CAPTCHA',
  YOUTUBE: 'YOUTUBE',
  ONE_SIGNAL: 'ONE_SIGNAL',
  GOOGLE_OAUTH: 'GOOGLE_OAUTH',
  TWITTER_CARD: 'TWITTER_CARD',
  ROBOTS_ROTATION: 'ROBOTS_ROTATION',
  ECONOMIC_CALENDAR: 'ECONOMIC_CALENDAR',
  LIVE_CHARTS_WIDGETS: 'LIVE_CHARTS_WIDGETS',
  PAGE_RELOADED_COOKIE: 'PAGE_RELOADED_COOKIE',
  FACEBOOK: 'FACEBOOK',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',

  /**
   * For unknown entities it's safe to assume that consent
   * to ALL cookie categories is required
   *
   * # Example: dynamic scripts/iframes fetched from BE (article body)
   */
  UNKNOWN: 'UNKNOWN'
}

/**
 * Full list of prior blocked services
 */
export const REQUIRED_COOKIE_CATEGORIES_BY_COOKIE_ENTITY_TYPE = {
  [COOKIE_ENTITY_TYPE.DFP_BANNER]: [COOKIE_CATEGORY.TARGETING],
  [COOKIE_ENTITY_TYPE.VIDEO_BANNER]: [COOKIE_CATEGORY.TARGETING],
  [COOKIE_ENTITY_TYPE.DISQUS]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING,
    COOKIE_CATEGORY.PERFORMANCE
  ],
  [COOKIE_ENTITY_TYPE.RE_CAPTCHA]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.YOUTUBE]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.ONE_SIGNAL]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.PERFORMANCE,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.GOOGLE_OAUTH]: [
    COOKIE_CATEGORY.FUNCTIONAL,
    COOKIE_CATEGORY.TARGETING
  ],
  [COOKIE_ENTITY_TYPE.TWITTER_CARD]: MAIN_COOKIE_CATEGORY_VALUES,
  [COOKIE_ENTITY_TYPE.ROBOTS_ROTATION]: [COOKIE_CATEGORY.FUNCTIONAL],
  [COOKIE_ENTITY_TYPE.ECONOMIC_CALENDAR]: [COOKIE_CATEGORY.FUNCTIONAL],
  [COOKIE_ENTITY_TYPE.LIVE_CHARTS_WIDGETS]: [COOKIE_CATEGORY.PERFORMANCE],
  [COOKIE_ENTITY_TYPE.PAGE_RELOADED_COOKIE]: [COOKIE_CATEGORY.FUNCTIONAL],
  [COOKIE_ENTITY_TYPE.FACEBOOK]: MAIN_COOKIE_CATEGORY_VALUES,
  [COOKIE_ENTITY_TYPE.GOOGLE_ANALYTICS]: [COOKIE_CATEGORY.PERFORMANCE],
  [COOKIE_ENTITY_TYPE.UNKNOWN]: MAIN_COOKIE_CATEGORY_VALUES
}

export const OPTANON_CONSENT_COOKIE_NAME = 'OptanonConsent'
export const OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed'

export const HANDLER_TYPE = {
  GLOBAL: 'global',
  LOCAL: 'local'
}

export const HANDLER_TYPE_BY_ENTITY_TYPE = {
  [COOKIE_ENTITY_TYPE.DFP_BANNER]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.VIDEO_BANNER]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.DISQUS]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.RE_CAPTCHA]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.YOUTUBE]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.ONE_SIGNAL]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.GOOGLE_OAUTH]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.TWITTER_CARD]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.FACEBOOK]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.GOOGLE_ANALYTICS]: HANDLER_TYPE.GLOBAL,
  [COOKIE_ENTITY_TYPE.LIVE_CHARTS_WIDGETS]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.PAGE_RELOADED_COOKIE]: HANDLER_TYPE.LOCAL,
  [COOKIE_ENTITY_TYPE.UNKNOWN]: HANDLER_TYPE.LOCAL
}

export const ONETRUST_POPUP_WRAPPER_ID = 'onetrust-consent-sdk'
