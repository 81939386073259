import { DFP_BANNER } from 'enums/banners/dfp-banner'
import { printScssBannerSettings } from '@/utils/helpers/banners'

/**
 * This breakpoint was introduced by the requirement to display sky banners of 160px width where
 * it is possible considering the obligatory 50px of distance between banner and the content.
 * It was decided not to put this breakpoint into "breakpoint" enum since it is only related
 * to sky banners.
 */
export const WIDE_SKY_BANNERS_BREAKPOINT_NAME = 'wide-sky-banners'
export const WIDE_SKY_BANNERS_BREAKPOINT_VALUE = 1970

export const getInsideListBannerSettingsKey = number =>
  `INSIDE_LIST_BANNER_${number}`
export const getTopForexBrokersBannerSettingsKey = number =>
  `TOP_FOREX_BROKERS_BANNER_${number}`

const TOP_SIDE = {
  mobile: DFP_BANNER.TOP_SIDE_320x50,
  tablet: DFP_BANNER.TOP_SIDE_750x250,
  desktopSm: DFP_BANNER.TOP_SIDE_980x250,
  desktopSm2: DFP_BANNER.TOP_SIDE_1140x250,
  desktopSm3: DFP_BANNER.TOP_SIDE_1140x250,
  desktopMd: DFP_BANNER.TOP_SIDE_1140x250,
  desktopLg: DFP_BANNER.TOP_SIDE_1140x250,
  desktopXl: DFP_BANNER.TOP_SIDE_1140x250,
  sidebarSmallSize: DFP_BANNER.TOP_SIDE_1140x250,
  aboveDesktopXl: DFP_BANNER.TOP_SIDE_1140x250,
  [WIDE_SKY_BANNERS_BREAKPOINT_NAME]: DFP_BANNER.TOP_SIDE_1140x250
}

const RIGHT_SIDE_1 = {
  desktopSm3: DFP_BANNER.RIGHT_SIDE_1_120x600,
  desktopMd: DFP_BANNER.RIGHT_SIDE_1_300x600,
  desktopLg: DFP_BANNER.RIGHT_SIDE_1_300x600,
  desktopXl: DFP_BANNER.RIGHT_SIDE_1_300x600,
  sidebarSmallSize: DFP_BANNER.RIGHT_SIDE_1_300x600,
  aboveDesktopXl: DFP_BANNER.RIGHT_SIDE_1_300x600
}

const RIGHT_SIDE_2 = {
  desktopSm3: DFP_BANNER.RIGHT_SIDE_2_120x600,
  desktopMd: DFP_BANNER.RIGHT_SIDE_2_300x600,
  desktopLg: DFP_BANNER.RIGHT_SIDE_2_300x600,
  desktopXl: DFP_BANNER.RIGHT_SIDE_2_300x600,
  sidebarSmallSize: DFP_BANNER.RIGHT_SIDE_2_300x600,
  aboveDesktopXl: DFP_BANNER.RIGHT_SIDE_2_300x600
}

export const BANNER_SETTINGS = {
  ALL_NEWS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    EXPANDED_CONTENT_BANNER: {
      tablet: DFP_BANNER.VIEW_MORE_468x60,
      desktopSm: DFP_BANNER.VIEW_MORE_468x60,
      desktopSm2: DFP_BANNER.VIEW_MORE_728x90,
      desktopSm3: DFP_BANNER.VIEW_MORE_468x60,
      desktopMd: DFP_BANNER.VIEW_MORE_468x60,
      desktopLg: DFP_BANNER.VIEW_MORE_468x60,
      desktopXl: DFP_BANNER.VIEW_MORE_468x60,
      sidebarSmallSize: DFP_BANNER.VIEW_MORE_468x60,
      aboveDesktopXl: DFP_BANNER.VIEW_MORE_468x60
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    }
  },
  ARTICLE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    INSIDE_BODY: {
      mobile: DFP_BANNER.ARTICLE_MPU_300x250,
      tablet: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    },
    AUTOMATED_LINK: {
      mobile: DFP_BANNER.AUTOMATED_LINK_290x88,
      tablet: DFP_BANNER.AUTOMATED_LINK_658x54,
      desktopSm: DFP_BANNER.AUTOMATED_LINK_584x54,
      desktopSm2: DFP_BANNER.AUTOMATED_LINK_768x54,
      desktopSm3: DFP_BANNER.AUTOMATED_LINK_584x54,
      desktopMd: DFP_BANNER.AUTOMATED_LINK_553x54,
      desktopLg: DFP_BANNER.AUTOMATED_LINK_584x54,
      desktopXl: DFP_BANNER.AUTOMATED_LINK_658x54,
      sidebarSmallSize: DFP_BANNER.AUTOMATED_LINK_658x54,
      aboveDesktopXl: DFP_BANNER.AUTOMATED_LINK_658x54
    },
    LOCKER_DOME_BANNER_BELOW_CONTENT: {
      mobile: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      tablet: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopSm: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopMd: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopLg: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopXl: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      sidebarSmallSize: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      aboveDesktopXl: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID
    }
  },
  SIDEBAR: {
    MIDDLE_BANNER: {
      desktopSm: DFP_BANNER.MPU_1_300x250,
      desktopSm2: DFP_BANNER.MPU_1_300x250,
      desktopSm3: DFP_BANNER.MPU_1_300x250,
      desktopMd: DFP_BANNER.MPU_1_300x250,
      desktopLg: DFP_BANNER.MPU_1_300x250,
      desktopXl: DFP_BANNER.MPU_1_300x250,
      sidebarSmallSize: DFP_BANNER.MPU_1_300x250,
      aboveDesktopXl: DFP_BANNER.MPU_1_300x250
    },
    BOTTOM_BANNER_1: {
      desktopSm: DFP_BANNER.MPU_2_300x250,
      desktopSm2: DFP_BANNER.MPU_2_300x250,
      desktopSm3: DFP_BANNER.MPU_2_300x250,
      desktopMd: DFP_BANNER.MPU_2_300x250,
      desktopLg: DFP_BANNER.MPU_2_300x250,
      desktopXl: DFP_BANNER.MPU_2_300x250,
      sidebarSmallSize: DFP_BANNER.MPU_2_300x250,
      aboveDesktopXl: DFP_BANNER.MPU_2_300x250
    },
    BOTTOM_BANNER_2: {
      desktopSm: DFP_BANNER.DMPU_300x600,
      desktopSm2: DFP_BANNER.DMPU_300x600,
      desktopSm3: DFP_BANNER.DMPU_300x600,
      desktopMd: DFP_BANNER.DMPU_300x600,
      desktopLg: DFP_BANNER.DMPU_300x600,
      desktopXl: DFP_BANNER.DMPU_300x600,
      sidebarSmallSize: DFP_BANNER.DMPU_300x600,
      aboveDesktopXl: DFP_BANNER.DMPU_300x600
    },
    [getTopForexBrokersBannerSettingsKey(1)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_1_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_1_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_1_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_1_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_1_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_1_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_1_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_1_90x40
    },
    [getTopForexBrokersBannerSettingsKey(2)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_2_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_2_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_2_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_2_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_2_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_2_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_2_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_2_90x40
    },
    [getTopForexBrokersBannerSettingsKey(3)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_3_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_3_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_3_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_3_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_3_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_3_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_3_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_3_90x40
    },
    [getTopForexBrokersBannerSettingsKey(4)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_4_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_4_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_4_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_4_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_4_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_4_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_4_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_4_90x40
    },
    [getTopForexBrokersBannerSettingsKey(5)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_5_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_5_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_5_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_5_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_5_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_5_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_5_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_5_90x40
    },
    [getTopForexBrokersBannerSettingsKey(6)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_6_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_6_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_6_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_6_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_6_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_6_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_6_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_6_90x40
    },
    [getTopForexBrokersBannerSettingsKey(7)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_7_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_7_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_7_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_7_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_7_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_7_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_7_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_7_90x40
    },
    [getTopForexBrokersBannerSettingsKey(8)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_8_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_8_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_8_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_8_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_8_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_8_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_8_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_8_90x40
    },
    [getTopForexBrokersBannerSettingsKey(9)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_9_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_9_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_9_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_9_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_9_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_9_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_9_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_9_90x40
    },
    [getTopForexBrokersBannerSettingsKey(10)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_10_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_10_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_10_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_10_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_10_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_10_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_10_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_10_90x40
    },
    [getTopForexBrokersBannerSettingsKey(11)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_11_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_11_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_11_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_11_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_11_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_11_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_11_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_11_90x40
    },
    [getTopForexBrokersBannerSettingsKey(12)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_12_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_12_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_12_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_12_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_12_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_12_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_12_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_12_90x40
    },
    [getTopForexBrokersBannerSettingsKey(13)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_13_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_13_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_13_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_13_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_13_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_13_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_13_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_13_90x40
    },
    [getTopForexBrokersBannerSettingsKey(14)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_14_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_14_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_14_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_14_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_14_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_14_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_14_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_14_90x40
    },
    [getTopForexBrokersBannerSettingsKey(15)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_15_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_15_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_15_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_15_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_15_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_15_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_15_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_15_90x40
    },
    [getTopForexBrokersBannerSettingsKey(16)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_16_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_16_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_16_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_16_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_16_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_16_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_16_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_16_90x40
    },
    [getTopForexBrokersBannerSettingsKey(17)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_17_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_17_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_17_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_17_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_17_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_17_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_17_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_17_90x40
    },
    [getTopForexBrokersBannerSettingsKey(18)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_18_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_18_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_18_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_18_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_18_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_18_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_18_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_18_90x40
    },
    [getTopForexBrokersBannerSettingsKey(19)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_19_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_19_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_19_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_19_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_19_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_19_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_19_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_19_90x40
    },
    [getTopForexBrokersBannerSettingsKey(20)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_20_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_20_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_20_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_20_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_20_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_20_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_20_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_20_90x40
    },
    [getTopForexBrokersBannerSettingsKey(21)]: {
      desktopSm: DFP_BANNER.BROKER_BUTTON_21_90x40,
      desktopSm2: DFP_BANNER.BROKER_BUTTON_21_90x40,
      desktopSm3: DFP_BANNER.BROKER_BUTTON_21_90x40,
      desktopMd: DFP_BANNER.BROKER_BUTTON_21_90x40,
      desktopLg: DFP_BANNER.BROKER_BUTTON_21_90x40,
      desktopXl: DFP_BANNER.BROKER_BUTTON_21_90x40,
      sidebarSmallSize: DFP_BANNER.BROKER_BUTTON_21_90x40,
      aboveDesktopXl: DFP_BANNER.BROKER_BUTTON_21_90x40
    },
    START_TRADING: {
      desktopSm: DFP_BANNER.START_TRADING_240x40,
      desktopSm2: DFP_BANNER.START_TRADING_240x40,
      desktopSm3: DFP_BANNER.START_TRADING_240x40,
      desktopMd: DFP_BANNER.START_TRADING_240x40,
      desktopLg: DFP_BANNER.START_TRADING_240x40,
      desktopXl: DFP_BANNER.START_TRADING_240x40,
      sidebarSmallSize: DFP_BANNER.START_TRADING_240x40,
      aboveDesktopXl: DFP_BANNER.START_TRADING_240x40
    }
  },
  RSS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  CONTACT_US: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  TERMS_HP: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    }
  },
  TERMS_LETTER: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    }
  },
  SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    NEWS_RESULTS_BOTTOM: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    COMPANIES_RESULTS_BOTTOM: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    TERMS_RESULTS_BOTTOM: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    }
  },
  TAG_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    }
  },
  TAG_SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  TERM_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    RELATED_TERMS_BOTTOM: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    }
  },
  TERMS_SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  LIVE_CHARTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  LIVE_QUOTES: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  ECONOMIC_CALENDAR: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  AUTHOR_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    }
  },
  CATEGORY: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  DIRECTORY_CATEGORY: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    }
  },
  DIRECTORY_COMPANY: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  EDUCATION: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_1_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_468x60
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_2_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_2_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_2_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_468x60
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_3_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_3_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_3_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_468x60
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_4_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_4_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_4_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_468x60
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm2: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm3: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopMd: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopLg: DFP_BANNER.LEADERBOARD_5_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_5_468x60,
      sidebarSmallSize: DFP_BANNER.LEADERBOARD_5_468x60,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_468x60
    }
  },
  BECOME_AN_AUTHOR: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  WIDGET_PAGES: {
    TRADE_THE_NEWS: {
      mobile: DFP_BANNER.TRADE_THE_NEWS_160x40,
      tablet: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopSm: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopSm2: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopSm3: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopMd: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopLg: DFP_BANNER.TRADE_THE_NEWS_160x40,
      desktopXl: DFP_BANNER.TRADE_THE_NEWS_160x40,
      sidebarSmallSize: DFP_BANNER.TRADE_THE_NEWS_160x40,
      aboveDesktopXl: DFP_BANNER.TRADE_THE_NEWS_160x40
    },
    LEARN_TO_TRADE: {
      mobile: DFP_BANNER.LEARN_TO_TRADE_318x40,
      tablet: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopSm: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopSm2: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopSm3: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopMd: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopLg: DFP_BANNER.LEARN_TO_TRADE_318x40,
      desktopXl: DFP_BANNER.LEARN_TO_TRADE_318x40,
      sidebarSmallSize: DFP_BANNER.LEARN_TO_TRADE_318x40,
      aboveDesktopXl: DFP_BANNER.LEARN_TO_TRADE_318x40
    }
  }
}

const isDevelopment = process.env.NODE_ENV === 'development'
if (isDevelopment && process.client) {
  printScssBannerSettings(BANNER_SETTINGS)
}

// /** The code below allows to verify all the needed banners class names **/
// /* eslint-disable */
// const bannerClassesSet = new Set()
// const BANNER_CLASSES = Object.entries(BANNER_SETTINGS).reduce(
//   (acc, [page, pageSettings]) => ({
//     ...acc,
//     [page]: Object.entries(pageSettings).reduce(
//       (acc, [banner, bannerSettings]) => ({
//         ...acc,
//         [banner]: Object.entries(bannerSettings).reduce(
//           (acc, [breakpoint, breakpointBannerSetting]) => {
//             const [bannerWidth, bannerHeight] = breakpointBannerSetting.sizes
//
//             const className = `${breakpoint}_${bannerWidth}${
//               bannerHeight ? `x${bannerHeight}` : ''
//             }`
//             bannerClassesSet.add(className)
//
//             return `${acc} ${className}`
//           },
//           ''
//         )
//       }),
//       {}
//     )
//   }),
//   {}
// )
// console.log('allUniqueBannerClasses', bannerClassesSet)
