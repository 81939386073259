<template>
  <div
    v-show="!isBannerHidden"
    :data-ref="REFS.BANNER"
    class="unit__outer-wrapper"
    :class="bannerClassesOuterWrapper"
    v-observe-visibility="visibilityOptions"
  >
    <a-visibility v-show="currentBanner" show :on="[$breakpoint.mobile]">
      <span class="banner__info">ADVERTISEMENT - CONTINUE READING BELOW</span>
    </a-visibility>

    <div :class="bannerClasses" :style="currentStyles">
      <client-only>
        <div
          v-if="currentBanner"
          :id="currentBannerId"
          :key="key"
          class="banner"
        />
        <div class="banner__overlay-for-sticky" />
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { pathOr } from 'ramda'
import { REFS } from 'enums/external-refs'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import {
  TARGETING_KEY,
  TARGETING_SETTINGS_BY_PAGE_NAME,
  TESTING_TARGETING_VALUE
} from 'enums/banners/banner-targeting'
import * as types from '@/store/mutation-types'
import { sraBannerHandler } from 'enums/banners/sra-display-handler'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { QUERY_PARAM } from 'enums/query-param'
import { ENVIRONMENT } from 'enums/environment'
import {
  BANNER_ALLOCATOR_PLACEHOLDER_BY_BREAKPOINT,
  BANNER_ALLOCATOR_PLACEHOLDER
} from 'enums/banners/banner-classes'

const MAX_TARGETING_VALUE_LENGTH = 40
const FLUID_BANNER_STRING = 'fluid'

export default {
  name: 'ABanner',
  mixins: [hydrationHelpers],
  props: {
    bannerSettings: propValidator([PROP_TYPES.OBJECT], false, () => {}),
    mocked: propValidator([PROP_TYPES.BOOLEAN], false, false),
    useRandomBannerId: propValidator([PROP_TYPES.BOOLEAN], false),
    bannerStyle: propValidator([PROP_TYPES.OBJECT], false, () => {}),
    customBreakpointHandler: propValidator([PROP_TYPES.FUNCTION], false),
    noAdvertisementLabel: propValidator([PROP_TYPES.BOOLEAN], false, false),

    /**
     * We use "bannerCampaignIsEmptyById" from store to define if a specific banner was collapsed.
     * If this prop is set to true, banner's wrapper will be hidden if its slot by id is marked
     * as hidden.
     *
     * # Important note: we can't simply use googletag.pubads().collapseEmptyDivs(), because we use
     * a wrapper that reserves a space equivalent to banner's size to preserve the markup from "jumps"
     */
    collapseIfEmpty: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      oldKey: '',
      currentBannerId: '',
      key: '',
      REFS,
      wasInViewport: false,
      visibilityOptions: {
        callback: this.setBannerInViewportState,
        once: true
      }
    }
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode',
      isBannersInitialized: 'isBannersInitialized',
      bannerSlots: 'bannerSlots',
      activeBannerSlots: 'activeBannerSlots',
      bannerCampaignIsEmptyById: 'bannerCampaignIsEmptyById'
    }),
    isCampaignEmpty() {
      return this.bannerCampaignIsEmptyById[this.bannerId]
    },
    isBannerHidden() {
      return this.collapseIfEmpty && this.isCampaignEmpty
    },
    isMockedBanners() {
      if (this.mocked) return true

      const isDevelopment = process.env.NODE_ENV === 'development'
      const isStage = this.$env.ENVIRONMENT === ENVIRONMENT.STAGE
      const isMockedByQuery = QUERY_PARAM.MOCKED_BANNERS in this.$route.query

      return isMockedByQuery && (isDevelopment || isStage)
    },
    bannerClasses() {
      return [
        ...this.getBannerSizeClassArray(),
        BANNER_ALLOCATOR_PLACEHOLDER.BANNER_SPACE_ALLOCATOR,
        {
          mocked: this.isMockedBanners
        },
        { preview: this.isPreviewMode }
      ]
    },
    bannerClassesOuterWrapper() {
      return {
        empty: !this.currentBanner,
        'no-advertisement-label': this.noAdvertisementLabel
      }
    },
    currentStyles() {
      return this.currentBanner ? this.bannerStyle : {}
    },
    currentLayout() {
      if (!this.$_hydrationHelpers_windowWidth) {
        return this.$_hydrationHelpers_isLayoutMobile ? 'mobile' : 'tablet'
      }

      if (this.customBreakpointHandler) {
        const breakpointName = this.customBreakpointHandler(
          this.$_hydrationHelpers_windowWidth
        )

        if (breakpointName) return breakpointName
      }

      const currentBreakpoint = this.$_hydrationHelpers_getCurrentBreakpoint()

      return (currentBreakpoint && currentBreakpoint.name) || null
    },
    currentBanner() {
      return pathOr(null, [this.currentLayout], this.bannerSettings)
    },
    bannerId() {
      return this.mocked
        ? ''
        : this.useRandomBannerId
        ? this.$helper.guid() + pathOr(null, ['id'], this.currentBanner)
        : pathOr(null, ['id'], this.currentBanner)
    },
    isSlotExist() {
      return Object.keys(this.bannerSlots).includes(this.currentBannerId)
    },
    targetingSettings() {
      return TARGETING_SETTINGS_BY_PAGE_NAME[this.$route.name]
    },
    targetingSettingsString() {
      return (this.targetingSettings || []).reduce(
        (acc, setting) =>
          acc.concat(`${setting.key}:${setting.valueHandler(this)};`),
        ''
      )
    },
    isInitializedAndLoaded() {
      /**
       * Banner has to be shown whenever either of these parameters changes
       * Using watcher on this computed property does the trick
       **/
      return JSON.stringify({
        isBannersInitialized: this.isBannersInitialized,
        currentBanner: this.currentBanner,
        targetingSettings: this.targetingSettingsString,
        isBannerHidden: !!this.isBannerHidden,
        wasInViewport: this.wasInViewport
      })
    },
    isNewBannerInitialized() {
      return this.key && this.oldKey && this.key !== this.oldKey
    }
  },
  watch: {
    isInitializedAndLoaded: {
      immediate: true,
      handler() {
        if (
          this.mocked ||
          this.isPreviewMode ||
          !this.isBannersInitialized ||
          !this.wasInViewport
        ) {
          return
        }

        if (process.browser) {
          this.oldKey = this.key
          this.key = this.$helper.guid()
          this.$nextTick(() => {
            this.registerSRAHandlers()

            if (this.isNewBannerInitialized) {
              this.removeActiveBannerSlot(this.oldKey)
            }
          })
        }
      }
    },
    currentBanner(newVal, oldVal) {
      /**
       * We need to destroy banners when following scenarios occur:
       * 1) When new banner settings equal "null"
       * 2) When new banner settings have different sizes (this means we can't reuse a banner slot)
       */
      if (oldVal && (!newVal || (newVal && oldVal.id !== newVal.id))) {
        this.removeBannerSlot({
          key: this.oldKey,
          id: this.currentBannerId
        })
      }
    },
    isCampaignEmpty(newVal) {
      if (newVal == null) return

      this.$emit('campaign-is-empty-status', newVal)
    }
  },
  methods: {
    ...mapActions({
      setBannerSlot: 'setBannerSlot',
      setActiveBannerSlot: 'setActiveBannerSlot',
      removeBannerSlot: 'removeBannerSlot'
    }),
    ...mapMutations({
      removeActiveBannerSlot: types.REMOVE_ACTIVE_BANNER_SLOT
    }),
    setBannerInViewportState(isVisible) {
      if (!isVisible) return

      this.wasInViewport = isVisible
    },
    /**
     * The execution of this handler is postponed using "sraBannerHandler"
     * to ensure the banner is requested in SRA
     */
    displayBannerHandler() {
      if (!this.currentBanner || this.isSlotExist) return

      const slot = window.googletag.defineSlot(
        this.currentBanner.path,
        this.currentBanner.sizes,
        this.currentBannerId
      )

      if (slot) {
        slot.addService(window.googletag.pubads())
        this.setBannerSlot({
          id: this.currentBannerId,
          slot
        })

        this.targetBanner(this.bannerSlots[this.currentBannerId])
        this.setActiveBannerSlot({ [this.key]: slot })
      }

      return slot
    },
    async registerSRAHandlers() {
      if (
        !this.isBannersInitialized ||
        this.isBannerHidden ||
        !this.bannerId ||
        !process.client ||
        !this.currentBanner
      ) {
        return
      }

      this.currentBannerId = this.bannerId

      await this.$nextTick()

      if (!this.isSlotExist) {
        sraBannerHandler.registerDisplayBannerHandler({
          handler: this.displayBannerHandler.bind(this),
          bannerId: this.currentBannerId
        })
      } else {
        this.setActiveBannerSlot({
          [this.key]: this.bannerSlots[this.currentBannerId]
        })
        this.targetBanner(this.bannerSlots[this.currentBannerId])

        sraBannerHandler.registerRefreshBannerSlot(
          this.bannerSlots[this.currentBannerId]
        )
      }
    },
    isTesting() {
      return !!this.$route.query[QUERY_PARAM.TEST_BANNERS]
    },
    targetBanner(slot) {
      if (!slot) return

      const currentTargetingKeys = slot.getTargetingKeys()

      /**
       * clear old targeting before setting a new one
       */
      if (currentTargetingKeys.length) {
        slot.clearTargeting()
      }

      if (this.isTesting()) {
        slot.setTargeting(TARGETING_KEY.DEFAULT, [TESTING_TARGETING_VALUE])
        return
      }

      if (!this.targetingSettings) return

      this.targetingSettings.forEach(({ key, valueHandler }) => {
        slot.setTargeting(key, [
          valueHandler(this).slice(0, MAX_TARGETING_VALUE_LENGTH)
        ])
      })
    },
    isSizeFluid(size) {
      if (!size || !this.$helper.isArray(size)) return false

      return size[0] === FLUID_BANNER_STRING || (size[0] === 1 && size[1] === 1)
    },
    isSizeNotFluid(size) {
      return !this.isSizeFluid(size)
    },
    getBannerDimensions(sizes) {
      if (Array.isArray(sizes[0])) {
        const sizesWithoutFluid = sizes.filter(this.isSizeNotFluid)

        const maxWidth = sizesWithoutFluid.reduce(
          (acc, [width]) => Math.max(width, acc),
          0
        )
        const maxHeight = sizesWithoutFluid.reduce(
          (acc, [_, height]) => Math.max(height, acc),
          0
        )
        return { bannerWidth: maxWidth, bannerHeight: maxHeight }
      } else {
        return { bannerWidth: sizes[0], bannerHeight: sizes[1] }
      }
    },
    getBannerSizeClassArray() {
      if (!this.bannerSettings) return []

      return Object.entries(this.bannerSettings).reduce(
        (acc, [breakpoint, breakpointBannerSetting]) => {
          const { bannerWidth, bannerHeight } = this.getBannerDimensions(
            breakpointBannerSetting.sizes
          )
          const placeholder =
            BANNER_ALLOCATOR_PLACEHOLDER_BY_BREAKPOINT[breakpoint]

          if (!placeholder) return acc

          const className = `${placeholder}_${bannerWidth}${
            bannerHeight ? `x${bannerHeight}` : ''
          }`

          return [...acc, className]
        },
        []
      )
    }
  },
  beforeDestroy() {
    this.removeBannerSlot({ key: this.key, id: this.currentBannerId })
  }
}
</script>

<style lang="scss">
@import 'assets/scss/local/mixins/_banner.scss';

$mocked-banner-background: #c7d6f3;

// Do not rename this class to "banner__outer-wrapper" because AdBlocker
// will set "display: none;" on it
.unit__outer-wrapper {
  transition: margin 0.1s ease;
  display: flex;
  justify-content: center;

  @include mobile {
    margin: 0 auto 50px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.empty {
      margin: 0;
    }
  }

  .banner__info {
    display: block;
    text-align: center;
    margin: 18px 0;
    color: #bbb;
    font-family: $font-sans;
    font-size: 10px;
    line-height: 14px;
  }

  &.no-advertisement-label {
    .banner__info {
      display: none;
    }
  }

  .banner__overlay-for-sticky {
    display: none;
  }

  /* The following selectors are replaced during the build process.
 All values are generated randomly to avoid detection by AdBlockers.
 The values here must be synced with the values in ../enums/banners/banner-classes.js
 */
  $banner-space-allocator: 'banner-space-allocator';
  $size-mobile: 'banner-allocator-mobile-modifier';
  $size-tablet: 'banner-allocator-tablet-modifier';
  $size-desktop-sm: 'banner-allocator-desktop-sm-modifier';
  $size-desktop-sm2: 'banner-allocator-desktop-sm2-modifier';
  $size-desktop-sm3: 'banner-allocator-desktop-sm3-modifier';
  $size-desktop-md: 'banner-allocator-desktop-md-modifier';
  $size-desktop-lg: 'banner-allocator-desktop-lg-modifier';
  $size-desktop-xl: 'banner-allocator-desktlp-xl-modifier';
  $size-above-desktop-xl: 'banner-allocator-above-desktop-xl-modifier';

  $mocked-banner-background: #c7d6f3;

  .#{$banner-space-allocator} {
    display: flex;
    justify-content: center;
    position: relative;
    pointer-events: all;

    // ToDo: Example of a banner placeholder
    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    //
    //&::after {
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate(-50%, -50%);
    //  display: block;
    //  font-family: $font-montserrat;
    //  color: #555;
    //  content: 'The banner is hidden by advertisement blocker';
    //}

    &.preview {
      @include mockedBanner;
    }

    @include mobile {
      margin: 0 -#{$mobile-body-padding};
    }

    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
      content: '';
      cursor: pointer;
    }
  }

  .#{$banner-space-allocator}.mocked {
    background: $mocked-banner-background;
    outline: 1px solid purple;
  }

  /**
   * How to get the $bannerUsage from banner-settings:
   * 1. Run the project in Development environment
   * 2. Check "cssMapString" value in console.log and copy it to the clipboard
   * 3. Replace $bannerUsage map with the clipboard value
   */

  $above-desktop-xl: 'above-desktop-xl';

  $bannerUsage: (
    $wide-sky-banners: (
      $size1140x250
    ),
    $above-desktop-xl: (
      $size1140x250,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size658x54,
      $size728x90,
      $size90x40,
      $sizeFluid
    ),
    $desktop-xl: (
      $size1140x250,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size658x54,
      $size728x90,
      $size90x40,
      $sizeFluid
    ),
    $desktop-lg: (
      $size1140x250,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size584x54,
      $size728x90,
      $size90x40,
      $sizeFluid
    ),
    $desktop-md: (
      $size1140x250,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size553x54,
      $size90x40,
      $sizeFluid
    ),
    $desktop-sm3: (
      $size1140x250,
      $size120x600,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size584x54,
      $size728x90,
      $size90x40
    ),
    $desktop-sm2: (
      $size1140x250,
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size728x90,
      $size768x54,
      $size90x40
    ),
    $desktop-sm: (
      $size160x40,
      $size240x40,
      $size300x250,
      $size300x600,
      $size318x40,
      $size468x60,
      $size584x54,
      $size728x90,
      $size90x40,
      $size980x250,
      $sizeFluid
    ),
    $tablet: (
      $size160x40,
      $size318x40,
      $size468x60,
      $size658x54,
      $size728x90,
      $size750x250,
      $sizeFluid
    ),
    $mobile: (
      $size160x40,
      $size290x88,
      $size300x250,
      $size318x40,
      $size320x50,
      $sizeFluid
    )
  );

  @each $size in map-get($bannerUsage, $above-desktop-xl) {
    .#{$banner-space-allocator}.#{$size-above-desktop-xl}_#{getModifier($size)} {
      @include on-above-desktop-xl {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-xl) {
    .#{$banner-space-allocator}.#{$size-desktop-xl}_#{getModifier($size)} {
      @include on-desktop-xl {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-lg) {
    .#{$banner-space-allocator}.#{$size-desktop-lg}_#{getModifier($size)} {
      @include on-desktop-lg {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-md) {
    .#{$banner-space-allocator}.#{$size-desktop-md}_#{getModifier($size)} {
      @include on-desktop-md {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-sm3) {
    .#{$banner-space-allocator}.#{$size-desktop-sm3}_#{getModifier($size)} {
      @include on-desktop-sm3 {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-sm2) {
    .#{$banner-space-allocator}.#{$size-desktop-sm2}_#{getModifier($size)} {
      @include on-desktop-sm2 {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $desktop-sm) {
    .#{$banner-space-allocator}.#{$size-desktop-sm}_#{getModifier($size)} {
      @include on-desktop-sm {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $tablet) {
    .#{$banner-space-allocator}.#{$size-tablet}_#{getModifier($size)} {
      @include on-tablet {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }

  @each $size in map-get($bannerUsage, $mobile) {
    .#{$banner-space-allocator}.#{$size-mobile}_#{getModifier($size)} {
      @include mobile {
        @include setSize(getWidth($size), getHeight($size));

        .banner {
          @include setSize(getWidth($size), getHeight($size));
        }
      }
    }
  }
}
</style>
